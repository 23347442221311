import React from 'react';

const About = () => {
  return (
    <div name="about" className='w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white'>
        <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full '> { /* Flex is giving issues */ }
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-gray-500'>About</p>
            </div>
            <p className='text-xl mt-20'>
            Hello, 
            I’m a recent graduate from Metropolitan State University of Denver. I majored in Computer Information Systems with a concentration in Application Development. 
            I’ve studied academically learning planning concepts, data structures and algorithms. 
            I’ve spent my free time building projects in order to construct a strong programming foundation.
            </p>
            
            <br />

            <p className='text-xl mt-6 mb-6'> { /* Using margin-top of 6 to counter the flex issues */ } 
            MSU Denver has equipped me with strong programming fundamentals suitable for backend with Python, SQL and frontend with HTML/CSS/JS. 
            I graduated in the <strong><u>Spring of 2023</u></strong> and am excited to start my next journey as a software engineer. I've built a combination of web apps using different programming languages, to include frontend, backend, and full stack applications.
            I’m also very interested in not only learning new concepts and languages but building upon the languages I already know.
            </p>
        </div>
    </div>
  );
};

export default About