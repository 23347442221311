import React from 'react';
import chess from '../assets/portfolio/Chess_ss.jpg';
import ahec from '../assets/portfolio/BoxInspection2.png';
import reactWeather from '../assets/portfolio/reactWeather.jpg';
import pdfLink from '../assets/portfolio/Deliverable3.pdf';
import artSite from '../assets/portfolio/ArtSite.jpg';

const Portfolio = () => {


    const portfolios = [
        {
            id: 1,
            src: ahec,
            demo: pdfLink,
            code: 'https://github.com/Cerulian87/Extinguisher-Inspection-App'
        },
        {
            id: 2,
            src: chess,
            demo: 'https://cute-horse-6ff56a.netlify.app/',
            code: 'https://github.com/Cerulian87/Chess'
        },
        {
            id: 3,
            src: artSite,
            demo: 'https://eclectic-chaja-d538d4.netlify.app/',
            code: 'https://github.com/Cerulian87/Web-Dev-Final-Project'
        },
        {
            id: 4,
            src: reactWeather,
            demo: 'https://scintillating-nasturtium-4eb58f.netlify.app/',
            code: 'https://github.com/Cerulian87/Weather-App'
        },
    ]

  return (
    <div name='portfolio' className='pt-20 bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-screen'>
        <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-gray-500'>Portfolio</p>
                <p className='py-6'>Check out a few of my projects!</p>
            </div>

            <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 px-12 sm:px-0'>

            {
                portfolios.map(({id, src, demo, code}) => (
                    
                    <div key={id} className='shadow-md shadow-gray-600 rounded-lg'>
                        <img src={src} alt='' className='rounded-md duration-200 hover:scale-105' style={{ width: '300px', height: '200px' }} />
                        <div className='flex items-center justify-center'>

                        <a href={demo} target='_blank' rel='noreferrer'>
                        <button className='w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105'>Demo</button>
                        </a>

                        <a href={code} target='_blank' rel='noreferrer'>
                        <button className='w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105'>Code</button>
                        </a>

                        </div>
                    </div>
                    
                ))
            }
            </div>

            
        </div>
    </div>
  );
};

export default Portfolio